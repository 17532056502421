import styled from '@emotion/styled'
import { Close } from 'app/components/Common/Close'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Separator } from 'app/components/Common/Separator'
import { Gallery } from 'app/components/GalleryCategories/Gallery'
import { FlexBox } from 'app/components/Layout/FlexBox'
import useLockedBody from 'app/utils/useLockedBody'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import Masonry from 'react-masonry-component'

import { Filter } from './Filter'

export interface CategoryProps {
  thumbnails?: ImageProps[]
  title: string
}

export interface Props {
  categories: CategoryProps[]
  languageCode: string
  siteName?: string
  title?: string
}

export const GalleryCategories = memo(function GalleryCategories({
  categories,
  languageCode,
  title,
}: Props) {
  if (!categories) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [filteredCategory, setFilteredCategory] = useState('all')
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  let counter = 0
  let images: any = []

  const categoriesImages = categories.map((item: any) =>
    item.images.map((e: any) => images.push(e)),
  )

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      {title ? <Heading>{title}</Heading> : null}

      {categories.length > 1 ? (
        <Filters dial={5} row wrap>
          <Filter
            active={filteredCategory === 'all'}
            category="all"
            title={useVocabularyData('all', languageCode)}
            onClick={() => {
              setFilteredCategory('all')
            }}
          />
          {uniqBy(categories, 'title').map((item, index) => {
            const category = item.title
              .replace(/[^a-zA-Z]/g, '')
              .toLocaleLowerCase()
            return category ? (
              <Filter
                active={filteredCategory === category}
                category={category}
                key={index}
                onClick={(e) => {
                  setFilteredCategory(e.target.getAttribute('data-category'))
                }}
                title={item.title}
              />
            ) : undefined
          })}
        </Filters>
      ) : null}

      <Mosaic
        options={{
          columnWidth: 1,
          percentPosition: true,
        }}
      >
        {uniqBy(categories, 'title').map((list, index) => {
          const category = list.title
            .replace(/[^a-zA-Z]/g, '')
            .toLocaleLowerCase()
          if (!list.thumbnails) {
            return null
          }
          return (
            <React.Fragment key={index}>
              <Category>{list.title}</Category>
              {list.thumbnails.map((item, index) => {
                counter = counter + 1
                return (
                  <Thumb
                    className={
                      filteredCategory === category ||
                      filteredCategory === 'all'
                        ? 'visible'
                        : undefined
                    }
                    style={
                      item.width && item.height
                        ? { aspectRatio: '' + item.width / item.height }
                        : undefined
                    }
                    data-counter={counter}
                    key={index}
                    onClick={(e) => {
                      if (!modalGalleryStatus) {
                        setLocked(true)
                        setModalGalleryStatus(true)
                      } else {
                        setLocked(false)
                        setModalGalleryStatus(false)
                      }
                      const realClickedSlide =
                        Number(e.currentTarget.getAttribute('data-counter')) - 1
                      setClickedSlide(realClickedSlide)
                      setTimeout(() => {
                        setClickedSlide(null)
                      }, 100)
                    }}
                  >
                    <LazyLoadComponent>
                      <Image {...item} />
                    </LazyLoadComponent>
                  </Thumb>
                )
              })}
            </React.Fragment>
          )
        })}
      </Mosaic>

      <Modal className={modalGalleryStatus ? ' open' : undefined}>
        <Close
          onClick={() => {
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
          }}
        />
        <Gallery
          clickedSlide={clickedSlide}
          images={images}
          isOpen={modalGalleryStatus}
        />
      </Modal>
      <Separator />
    </Container>
  )
})

const Container = styled.section`
  padding: 14.375rem 0 7.5rem;

  @media (max-width: 1199px) {
    padding: 12.8125rem 0 5rem;
  }

  @media (max-width: 768px) {
    padding-top: 9.375rem;
  }
`

const Heading = styled.h1`
  max-width: 45rem;
  margin: 0 auto;
  padding: 0 1.5625rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 3.75rem;
  letter-spacing: 0.02em;
  line-height: 1.15;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 2.8125rem;
    line-height: 3.4375rem;
  }

  @media (max-width: 768px) {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
`

const Filters = styled(FlexBox)`
  margin: 5rem 0;

  @media (max-width: 1199px) {
    display: none;
  }
`

const Mosaic = styled(Masonry)<MasonryProps>`
  margin-left: -1.25rem;
  margin-bottom: -1.25rem;
`

const Category = styled.div`
  position: relative;
  display: none;
  width: 100%;
  margin-top: 3.75rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.8125rem;
  letter-spacing: 0.1em;
  line-height: 1.125rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 1.25rem;
    margin-bottom: 1.25rem;
  }
`

const Thumb = styled.div`
  position: relative;
  display: none;
  width: calc(24.98% - 1.25rem);
  max-height: 32.5rem;
  margin-left: 1.25rem;
  margin-bottom: 1.25rem;
  overflow: hidden;
  cursor: pointer;

  @media (max-width: 1920px) {
    width: calc(33.3% - 1.25rem);
  }

  @media (max-width: 992px) {
    width: calc(49.98% - 1.25rem);
  }

  &:hover {
    img {
      transform: scale(1.07);
    }
  }

  &.visible {
    display: block;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`

interface MasonryProps {
  children?: React.ReactNode | React.ReactNode[]
}
