import styled from '@emotion/styled'
import { ChevronLeft, ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  className?: string
  direction?: 'L' | 'R'
  disabled?: boolean
  onClick?: (e: any) => void
}

export const Arrow = memo(function Arrow({
  className,
  direction = 'L',
  disabled,
  onClick,
}: Props) {
  return (
    <Container
      dial={5}
      className={disabled ? 'disabled' : className}
      onClick={onClick}
    >
      {direction === 'L' ? <ChevronLeft /> : <ChevronRight />}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 3.75rem;
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.primaryLight};
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;
  transition: background 0.25s ease-in-out;

  @media (max-width: 768px) {
    width: 3.125rem;
    height: 3.125rem;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.variants.neutralLight1};
  }

  &.disabled {
    pointer-events: none;
  }

  &.transparent {
    background: transparent;

    &:hover {
      background: transparent;
    }
  }

  svg {
    width: auto;
    height: 1.1875rem;
    stroke: ${({ theme }) => theme.colors.variants.primaryDark};

    @media (max-width: 768px) {
      height: 0.989rem;
    }
  }
`
