import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  active?: boolean
  category: string
  onClick?: (e: any) => void
  title: string
}

export const Filter = memo(function Filter({
  active = false,
  category,
  onClick,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container
      className={active ? 'active' : undefined}
      data-category={category}
      onClick={onClick}
    >
      {title}
    </Container>
  )
})

const Container = styled.div`
  margin: 0 1.5625rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.6875rem;
  cursor: pointer;

  &.active {
    font-weight: 600;
    pointer-events: none;
  }
`
